export const USERS_ROUTE = "users";
export const SESSIONS_ROUTE = "sessions";
export const DICE_ROUTE = "dice";
export const MINE_ROUTE = "mine";
export const BLACKJACK_ROUTE = "blackjack";
export const HILO_ROUTE = "hilo";
export const MESSAGES_ROUTE = "messages";
export const TRANSACTIONS_ROUTE = "transactions";
export const FAIRNESS_ROUTE = "fairness";
export const FEED_ROUTE = "feed";
export const EXCHANGE_ROUTE = "exchange";
export const WAGER_ROUTE = "wager";
export const SLOTS_ROUTE = "slots";

export enum GAMESTATUS {
  START = "start",
  INGAME = "inGame",
  ENDGAME = "endGame",
}

export enum DUELTYPE {
  WHIP = "Whip",
  DDS = "Dds/Whip",
}

export enum SELECTIONSTATUS {
  RESET = "rest",
  VALUE = "value",
}
export const listOfMines = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24,
];

export const DEFAULT_DISABLED_MESSAGE = "This game is currently Disabled";
export const chatDrawerWidth = "17vw";
export const chatDrawerWidthMin = 300;
export const DEFAULT_MIN_BET = 0.1;

export const referralSystemArray = [
  {
    level: 1,
    multiplier: 0.1,
    tokens: 0,
  },
  {
    level: 2,
    multiplier: 0.125,
    tokens: 25000,
  },
  {
    level: 3,
    multiplier: 0.15,
    tokens: 100000,
  },
  {
    level: 4,
    multiplier: 0.2,
    tokens: 200000,
  },
];

export const parseHiddenUsername = (text: string, isAdmin: boolean) => {
  let usernameMatch = text.match(/~\[\[\[Hidden_(.+?)\]\]\]~/);

  if (usernameMatch && usernameMatch[1]) {
    let username = usernameMatch[1];

    if (isAdmin) {
      text = text.replace(/~\[\[\[Hidden_.+?\]\]\]~/, `__${username}`);
    } else {
      text = text.replace(/~\[\[\[Hidden_.+?\]\]\]~/, "Hidden");
    }
  }

  return text;
};

export const chatDictionary = [
  {
    command: "/games",
    path: "/games",
  },
  {
    command: "/slots",
    path: "/slots",
  },
  {
    command: "/duel-arena",
    path: "/game/duel-arena",
  },
  {
    command: "/staking",
    path: "/game/duel-arena",
  },
  {
    command: "/duel",
    path: "/game/duel-arena",
  },
  {
    command: "/plinko",
    path: "/game/plinko",
  },
  {
    command: "/keno",
    path: "/game/keno",
  },
  {
    command: "/slide",
    path: "/game/slide",
  },
  {
    command: "/dice",
    path: "/game/dice",
  },
  {
    command: "/blackjack",
    path: "/game/blackjack",
  },
  {
    command: "/mines",
    path: "/game/mines",
  },
  {
    command: "/limbo",
    path: "/game/limbo",
  },
  {
    command: "/coinflip",
    path: "/game/coinflip",
  },
  {
    command: "/bonus",
    path: "/rakeback",
  },
  {
    command: "/rewards",
    path: "/rakeback",
  },
  {
    command: "/rakeback",
    path: "/rakeback",
  },
  {
    command: "/referrals",
    path: "/affiliate",
  },
  {
    command: "/affiliate",
    path: "/affiliate",
  },

  {
    command: "/wager-race",
    path: "/wager-race",
  },
  {
    command: "/race",
    path: "/wager-race",
  },
  {
    command: "/fairness",
    path: "/fairness",
  },
  {
    command: "/profile",
    path: "/profile",
  },
  {
    command: "/orders",
    path: "/profile/order",
  },
  {
    command: "/transactions",
    path: "/profile/transaction",
  },
  {
    command: "/settings",
    path: "/profile/settings",
  },
  {
    command: "/favourites",
    path: "/favourites",
  },
  {
    command: "/discord",
    isDiff: true,
    path: "https://discord.gg/runewagers",
  },
];
