import { Stack, IconButton, Typography, useMediaQuery } from "@mui/material";
import { FormEvent, useContext, useRef, useState, memo } from "react";
import ChatContext from "../../contexts/chat/context";
import { InputFieldStyled } from "../inputField";
import {
  selectCurrentUser,
  selectPlayer,
  selectToken,
  updateIsTyping,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import Picker, { Theme } from "emoji-picker-react";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import { theme } from "../theme";
import { CHARACTER_LIMIT, CHATTYPES } from "../../constants/chat";
import { ADMIN_READ_AT_UPDATE } from "../../constants/socket";
import { selectAdminChat } from "../slices/chatSlice";
import { IMessage } from "../../types";
import { useDispatch } from "react-redux";
import GifPicker, { Theme as GIFTheme, ContentFilter } from "gif-picker-react";
import GifIcon from "@mui/icons-material/Gif";

export const MessageInput = ({
  chatType,
  orderId,
  selectedChat,
}: {
  chatType: CHATTYPES;
  orderId?: string;
  selectedChat: string;
}) => {
  const [newMessage, setNewMessage] = useState("");
  const accessToken = useAppSelector(selectToken);
  const user = useAppSelector(selectCurrentUser);
  const { socket } = useContext(ChatContext);
  const [showPicker, setShowPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const timerRef = useRef<number>(0);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const onEmojiClick = (emojiObject: any) => {
    setNewMessage((prevInput: string) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };
  const dispatch = useDispatch();
  const chatData = useAppSelector(selectAdminChat);

  const handleSendMessage = (event?: FormEvent, message = newMessage) => {
    if (event) {
      event.preventDefault();
    }
    const currentTime = Date.now();
    const startTime = timerRef?.current;
    if (
      message &&
      (!startTime || currentTime - startTime > 1000) &&
      chatType === CHATTYPES.Community
    ) {
      socket?.emit("new-message", {
        text: message,
        accessToken,
      });
      timerRef.current = currentTime;
      setNewMessage("");
      return;
    }

    if (
      message &&
      (!startTime || currentTime - startTime > 1000) &&
      (chatType === CHATTYPES.WITHDRAWAL || chatType === CHATTYPES.DEPOSIT)
    ) {
      socket?.emit("new-message-admin", {
        text: message,
        accessToken,
        orderId,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }

    if (message && chatType === CHATTYPES.ADMIN) {
      socket?.emit("new-message-admin", {
        text: message,
        accessToken,
        orderId: selectedChat,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }
  };

  const sendSocketReadAt = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      const selected = chatData.find((chat) => chat.order._id === selectedChat);
      const unReadMessages =
        selected?.messages?.filter(
          (data: IMessage) => !data?.readAt && !data.isAdmin
        ) || [];

      if (unReadMessages?.length) {
        socket?.emit(ADMIN_READ_AT_UPDATE, {
          accessToken,
          orderId: selectedChat,
        });
      }
    }
  };

  const handleOnFocus = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      sendSocketReadAt();
    }
    if (showPicker) {
      setShowPicker((val) => !val);
    }
    if (showGifPicker) {
      setShowGifPicker((val) => !val);
    }
    dispatch(updateIsTyping(true));
  };

  const loseFocus = () => {
    dispatch(updateIsTyping(false));
  };

  const handleGifClick = (data: any) => {
    if (data?.preview) {
      setShowGifPicker((val) => !val);
      handleSendMessage(undefined, JSON.stringify(data?.preview));
    }
  };
  return (
    <form onSubmit={handleSendMessage}>
      <Stack direction="column">
        <Stack direction="row" gap={1} sx={{ position: "relative" }}>
          <Stack sx={{ width: "100%", position: "relative" }}>
            <InputFieldStyled
              fullWidth
              disabled={
                (!selectedChat && chatType === CHATTYPES.ADMIN) ||
                (chatType === CHATTYPES.Community && user.isMuted)
              }
              onFocus={() => handleOnFocus()}
              onBlur={() => loseFocus()}
              value={newMessage}
              inputProps={{ maxLength: CHARACTER_LIMIT }}
              onChange={(e) => {
                setNewMessage(e.target.value);
                e.stopPropagation();
              }}
            />
          </Stack>
          <Stack direction="row">
            <IconButton
              onClick={() => {
                setShowPicker((val) => !val);
                if (showGifPicker) {
                  setShowGifPicker((val) => !val);
                }
              }}
              sx={{ p: 0.5 }}
            >
              <EmojiEmotionsOutlinedIcon
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "0.78em",
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setShowGifPicker((val) => !val);
                if (showGifPicker) {
                  setShowPicker((val) => !val);
                }
              }}
              sx={{ p: 0 }}
            >
              <GifIcon
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: "1.2em",
                }}
              />
            </IconButton>
          </Stack>

          {showPicker && (
            <EmojiPicker onEmojiClick={onEmojiClick} matchesMD={matchesMD} />
          )}
          {showGifPicker && (
            <GifPickerReact onGifClick={handleGifClick} matchesMD={matchesMD} />
          )}
        </Stack>
        <Typography
          sx={{
            fontSize: ".65em",
            zIndex: 1,
            textAlign: "end",
            marginRight: "15%",
          }}
          variant="subtitle1"
        >{`${newMessage.length}/${CHARACTER_LIMIT}`}</Typography>
      </Stack>
    </form>
  );
};
const EmojiPicker = memo(
  ({
    onEmojiClick,
    matchesMD,
  }: {
    onEmojiClick: (emojiObject: any) => void;
    matchesMD: boolean;
  }) => (
    <Picker
      lazyLoadEmojis
      style={{
        position: "absolute",
        width: matchesMD ? "290px" : "96vw",
        height: matchesMD ? "350px" : "350px",
        transform: "translate(-1%,-102%)",
      }}
      searchDisabled={true}
      skinTonesDisabled={true}
      theme={Theme.DARK}
      onEmojiClick={onEmojiClick}
    />
  )
);

const GifPickerReact = memo(
  ({
    onGifClick,
    matchesMD,
  }: {
    onGifClick: (data: any) => void;
    matchesMD: boolean;
  }) => (
    <Stack
      sx={{
        position: "absolute",
        width: matchesMD ? "290px" : "96vw",
        height: matchesMD ? "360px" : "450px",
        transform: "translate(-1%,-102%)",
      }}
    >
      <GifPicker
        theme={GIFTheme.DARK}
        width={matchesMD ? "290px" : "96vw"}
        height={matchesMD ? "360px" : "450px"}
        categoryHeight={100}
        contentFilter={ContentFilter.LOW}
        autoFocusSearch
        tenorApiKey="AIzaSyDpRp_pYFl2tFPhDlhbCPRaICOViuNmZj0"
        onGifClick={(data: any) => {
          onGifClick(data);
        }}
      />
    </Stack>
  )
);
