import {
  Container,
  Typography,
  Stack,
  Card,
  InputAdornment,
  MenuItem,
  Select,
  Grid,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  InputFieldStyled,
  InputFieldWithStartAdornment,
} from "../../components/inputField";
import { theme } from "../../components/theme";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "../../components/store/hooks";
import { ISlotGame, selectSlotGames } from "../../components/slices/slotsSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  bgamingSlotsReleaseDates,
  getImageEndpoint,
  getSlotsToRecentlyPlayed,
} from "../../constants/slots";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { games } from "../../constants/games";
const sortByList = [
  "All",
  "Alphabetical (A-Z)",
  "Alphabetical (Z-A)",
  // "Recently Played",
  // "Release Date",
];

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const Originals = () => {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("All");
  const [allGames, setAllGames] = useState(games);
  const navigate = useNavigate();

  console.log(games);
  useEffect(() => {
    // Filter games by search query
    const filteredGames = games.filter((game) =>
      game.name.toLowerCase().includes(search.toLowerCase())
    );

    // const recentlyPlayed = getSlotsToRecentlyPlayed();

    // if (sortBy === "Recently Played") {
    //   const sortedGames = [...filteredGames].sort((a, b) => {
    //     const indexA = recentlyPlayed.indexOf(a.identifier);
    //     const indexB = recentlyPlayed.indexOf(b.identifier);

    //     if (indexA !== -1 && indexB === -1) return -1;
    //     if (indexA === -1 && indexB !== -1) return 1;
    //     if (indexA !== -1 && indexB !== -1) return indexA - indexB;

    //     return 0;
    //   });

    //   setAllGames(sortedGames);
    //   return;
    // }

    // if (sortBy === "Release Date") {
    //   const sortedGames = [...filteredGames].sort((a, b) => {
    //     const releaseDateA = bgamingSlotsReleaseDates.find(
    //       (game) => game.identifier === a.identifier
    //     )?.ReleaseDate;
    //     const releaseDateB = bgamingSlotsReleaseDates.find(
    //       (game) => game.identifier === b.identifier
    //     )?.ReleaseDate;

    //     const dateA = releaseDateA ? new Date(releaseDateA) : new Date(0);
    //     const dateB = releaseDateB ? new Date(releaseDateB) : new Date(0);

    //     return dateB.getTime() - dateA.getTime();
    //   });

    //   setAllGames(sortedGames);
    //   return;
    // }

    // Apply sorting after filtering
    if (sortBy === "Alphabetical (A-Z)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setAllGames(sortedGames);
      return;
    }

    if (sortBy === "Alphabetical (Z-A)") {
      const sortedGames = [...filteredGames].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      setAllGames(sortedGames);
      return;
    }

    // If no sorting, just set filtered games
    setAllGames(filteredGames);
  }, [sortBy, search]);

  return (
    <Container maxWidth={"md"}>
      <Stack gap={1} justifyContent="flex-start" sx={{ minHeight: "60vh" }}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton
            sx={{
              bgcolor: "#1C2B39",
              height: "1.5em",
              maxWidth: "1.5em",
              fontSize: "1em",
              p: 2,
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosIcon
              fontSize="inherit"
              sx={{ ml: 1, color: theme.palette.text.primary }}
            />
          </IconButton>
          <Typography
            sx={{ textAlign: "center" }}
            variant="h5"
            fontWeight={600}
            color="text.primary"
          >
            Runewager Originals
          </Typography>
        </Stack>

        <Card sx={{ p: 2 }}>
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Stack
              sx={{ width: "50%" }}
              direction="row"
              gap={1}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography color="text.primary">Sort By:</Typography>
              <Select
                sx={{ width: "50%" }}
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                input={
                  <InputFieldStyled
                    // disabled={disableButtons}
                    sx={{
                      "& .MuiInputBase-input": {
                        "&.Mui-disabled": {
                          backgroundColor: "#2F5272",
                          WebkitTextFillColor: "black",
                        },
                      },
                    }}
                  />
                }
                MenuProps={MenuProps}
              >
                {sortByList.map((mine: string) => (
                  <MenuItem value={mine}>{mine}</MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Card>
        <Grid
          container
          spacing={{ md: 1, sm: 5, xs: 1 }}
          columns={12}
          sx={{
            gridTemplateColumns: {
              md: "repeat(4, 1fr)", // 4 items per row for md and larger
              sm: "repeat(2, 1fr)", // 2 items per row for sm screens
              xs: "repeat(2, 1fr)", // 1 item per row for xs screens
            },
            justifyContent: {
              md: "normal",
              sm: "normal",
              xs: "normal",
            },
          }}
        >
          {allGames.map((item, i) => {
            return (
              <Grid
                item
                key={item.url}
                className={styles.card}
                // minHeight={160}
                // size={{
                xs={4}
                sm={3}
                md={2}
                lg={1.7}
                // }}
              >
                <Link to={`/game/${item.url}`}>
                  <Stack
                  // sx={{
                  //   width: {
                  //     xl: "7.2vw",
                  //     lg: "10vw",
                  //     md: "11vw",
                  //     xs: "20vw",
                  //   },
                  // }}
                  >
                    <img
                      src={item.gif}
                      alt={item.name}
                      style={{ borderRadius: "8px" }}
                    />
                  </Stack>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};

export default Originals;

// <Stack justifyContent="space-between" sx={{ mt: 2 }}>

// <Stack
//   direction="row"
//   flexWrap={"wrap"}
//   justifyContent="flex-start"
//   gap={{ md: 1, xs: 1 }}
// >
//   {allGames.map((item, i) => {
//     return (
//       <Link to={`/slots/${item.identifier}`} key={item.identifier}>
//         <Stack
//           sx={{
//             width: {
//               xl: "7.4vw",
//               lg: "10vw",
//               md: "11vw",
//               xs: "20vw",
//             },
//             // width: "7.3vw",
//           }}
//         >
//           <img
//             src={`${DEFAULT_BG_IMAGE_S6}${item.identifier}.webp`}
//             alt={item.title}
//             style={{ borderRadius: "8px" }}
//           />

//         </Stack>
//       </Link>
//     );
//   })}
// </Stack>
// </Stack>
