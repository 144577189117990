import React, { useState, useEffect, useRef, useContext } from "react";
import { Stack, Typography, MenuItem, Select } from "@mui/material";
import Tile from "./tiles";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldStyled } from "../inputField";
import {
  selectLevelUpModal,
  selectMinesToggle,
  selectPlayer,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import styles from "./index.module.scss";
import type { AppDispatch } from "../store";
import useSound from "use-sound";
import { useDispatch } from "react-redux";
import {
  getMineErrorMessage,
  getMineLoading,
  resetGame,
  selectGame,
  selectPastGames,
  setErrorMesage,
  updateIsWin,
  updateLoading,
  updatePastGame,
} from "../slices/mineSlice";
import {
  DEFAULT_DISABLED_MESSAGE,
  GAMESTATUS,
  SELECTIONSTATUS,
  listOfMines,
} from "../../constants";
import { DICEMODE, TILETYPE, TOKENTYPE, tile } from "../../types/index.d";
import {
  getPayout,
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundProfitOnWin,
} from "../../utils";
import { WinModal } from "./winModal";
import { Buttons } from "./buttons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import {
  CHECK_MINES_GAME,
  PAYOUT_MINES_GAME,
  PLAY_MINES_GAME,
} from "../../constants/socket";
import ChatContext from "../../contexts/chat/context";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import OpenDiamondSound from "../../assets/audio/mines/diamond-reveal.mp3";
import OpenMineSound from "../../assets/audio/mines/bomb-reveal.mp3";
import MinesAutoButtons from "./betButton";
import { GameTypeButtons, IAutoPlayStates } from "../dice/diceButtons";
import TilesAuto from "./tilesAuto";

interface MinesProps {}

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#273F55",
    },
  },
};

const Mines: React.FC<MinesProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { player, accessToken } = useAppSelector(selectPlayer);
  const mineGame = useAppSelector(selectGame);
  const errorMessage = useAppSelector(getMineErrorMessage);
  const loading = useAppSelector(getMineLoading);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const [currentBet, setCurrentBet] = useState(0);
  const [numOfMines, setNumOfMines] = useState<number>(5);
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [playDiamondSound] = useSound(OpenDiamondSound, {
    volume: volume / 100,
  });
  const [playMineSound] = useSound(OpenMineSound, { volume: volume / 100 });
  const [revealedCells, setRevealedCells] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [grid, setGrid] = useState<string[][]>(
    Array(5)
      .fill(0)
      .map(() => Array(5).fill("💎"))
  );
  const tokenType = useAppSelector(selectTokenSelection);

  // Auto
  const timeOutRef = useRef<any>();
  const betRef = useRef<number>(0);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });

  const [autoPlayStatesRef, setAutoPlayStatesRef] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const [autoSelection, setAutoSelection] = useState<string[]>([]);
  const pastResult = useAppSelector(selectPastGames);
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const tokenTypeRef = useRef(tokenType);
  const { socket } = useContext(ChatContext);
  const openTilesRef = useRef<number>();
  const toggle = useAppSelector(selectMinesToggle);
  const lastCellHitRef = useRef("");
  const {
    mineLocations,
    gameStatus,
    openTiles,
    payout,
    isWin,
    multiplier,
    mines,
    bet,
  } = mineGame;
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  useEffect(() => {
    if (bet && bet !== currentBet) {
      setCurrentBet(bet);
    }
    if (mines && mines !== numOfMines) {
      setNumOfMines(mines);
    }
    // eslint-disable-next-line
  }, [bet, mines]);

  useEffect(() => {
    if (openTiles?.length && gameStatus === GAMESTATUS.INGAME) {
      const tempGrid = grid;
      let tempReveledCell = revealedCells;
      openTiles.forEach((tiles) => {
        const [column, row] = tiles.key.split(",").map(Number);
        if (tiles.type === TILETYPE.MINE) {
          tempGrid[row][column] = TILETYPE.MINE;
        }
        if (!tempReveledCell.includes(tiles.key)) {
          tempReveledCell = [...tempReveledCell, tiles.key];
        }
      });
      setGrid(tempGrid);
      setRevealedCells(tempReveledCell);
      return;
    }
    if (isRestart) return;
    if (mineLocations && gameStatus === GAMESTATUS.ENDGAME) {
      const tempGrid = grid;
      mineLocations.forEach((tiles) => {
        const [column, row] = tiles.split(",").map(Number);
        tempGrid[row][column] = TILETYPE.MINE;
      });
      let tempReveledCell = revealedCells;
      openTiles.forEach((tiles) => {
        const [column, row] = tiles.key.split(",").map(Number);
        if (tiles.type === TILETYPE.MINE) {
          tempGrid[row][column] = TILETYPE.MINE;
        }
        if (!tempReveledCell.includes(tiles.key)) {
          tempReveledCell = [...tempReveledCell, tiles.key];
        }
      });
      setRevealedCells(tempReveledCell);
      setGrid([...tempGrid]);
      return;
    }
    // eslint-disable-next-line
  }, [openTiles, mineLocations, gameStatus]);

  useEffect(() => {
    if (pastResult.length && isAutoPlaying) {
      const getLastGame = pastResult[pastResult.length - 1];
      let shouldContinue = isLevelUp ? false : true;
      let newBet = currentBet;
      let letLoss = autoPlayStatesRef.stopNetLoss;
      let netGain = autoPlayStatesRef.stopNetLoss;

      //Stop when token changes
      if (tokenTypeRef.current !== tokenType) {
        shouldContinue = false;
      }
      // Check Number of Games
      if (autoPlayStates.numberOfGames) {
        if (autoPlayStatesRef.numberOfGames > 1) {
          setAutoPlayStatesRef({
            ...autoPlayStatesRef,
            numberOfGames: autoPlayStatesRef.numberOfGames - 1,
          });
        } else {
          shouldContinue = false;
        }
      }

      if (autoPlayStates.onWin && getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onWin / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onWin / 100)).toFixed(2)
        );
      }

      if (autoPlayStates.onLoss && !getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onLoss / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onLoss / 100)).toFixed(2)
        );
      }

      if (
        autoPlayStates.winStatus === SELECTIONSTATUS.RESET &&
        getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (
        autoPlayStates.lossStatus === SELECTIONSTATUS.RESET &&
        !getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      // Net Loss
      if (!getLastGame.isWin && autoPlayStates.stopNetLoss) {
        letLoss = letLoss + getLastGame.bet;
        if (letLoss >= autoPlayStates.stopNetLoss) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetLoss: letLoss,
          stopNetGain: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      // Net Gain
      if (getLastGame.isWin && autoPlayStates.stopNetGain) {
        netGain = netGain + (getLastGame.payout - getLastGame.bet);
        if (netGain >= autoPlayStates.stopNetGain) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetGain: netGain,
          stopNetLoss: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      if (
        shouldContinue &&
        ((currentBet <= player.balance &&
          getLastGame.tokenType === TOKENTYPE.SWEEP) ||
          (currentBet <= player.freeCash &&
            getLastGame.tokenType === TOKENTYPE.FREE))
      ) {
        handleCreateGame(false);
      } else {
        handleReset();
        setIsAutoPlaying(false);
      }
    }
  }, [pastResult]);

  useEffect(() => {
    if (
      playType === DICEMODE.AUTO &&
      mineLocations &&
      gameStatus === GAMESTATUS.ENDGAME
    ) {
      const tempGrid = grid;
      mineLocations.forEach((tiles: any) => {
        const [column, row] = tiles.split(",").map(Number);
        tempGrid[row][column] = TILETYPE.MINE;
      });
      let tempReveledCell = revealedCells;
      openTiles.forEach((tiles: any) => {
        const [column, row] = tiles.key.split(",").map(Number);
        if (tiles.type === TILETYPE.MINE) {
          tempGrid[row][column] = TILETYPE.MINE;
        }
        if (!tempReveledCell.includes(tiles.key)) {
          tempReveledCell = [...tempReveledCell, tiles.key];
        }
      });
      setRevealedCells(tempReveledCell);
      setGrid([...tempGrid]);
      setIsRestart(false);
      timeOutRef.current = setTimeout(() => {
        dispatch(updatePastGame(mineGame));
        dispatch(updateIsWin(false));
      }, 1000);
    }

    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
    // eslint-disable-next-line
  }, [openTiles, mineLocations, gameStatus]);

  useEffect(() => {
    if (gameStatus === GAMESTATUS.INGAME && isRestart) {
      setIsRestart(false);
    }
    // eslint-disable-next-line
  }, [gameStatus]);

  const handleCellClick = (column: number, row: number): void => {
    if (loading) return;
    dispatch(updateLoading());
    const key = `${column},${row}`;
    lastCellHitRef.current = key;
    socket?.emit(CHECK_MINES_GAME, {
      key,
      accessToken,
    });
  };

  const handleCreateGame = (askConfirmation = true) => {
    if (title) {
      dispatch(setErrorMesage(title));
      return;
    }
    if (currentBet > player.balance && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      return;
    }

    if (bet > player.freeCash && tokenType === TOKENTYPE.FREE) {
      betFieldRef.current?.focus();
      return;
    }

    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be negative"));
      return;
    }
    if (currentBet === 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be 0"));
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet can't be lower than balance"));
      return;
    }
    if (numOfMines < 0) {
      dispatch(setErrorMesage("Mines should be greater than 0"));
      return;
    }

    if (currentBet && currentBet > 0 && numOfMines > 0) {
      if (
        tokenType === TOKENTYPE.SWEEP &&
        askConfirmation &&
        currentBet &&
        ((player.isBetConfirmation &&
          isBetGreaterthanSetValue(
            currentBet,
            player.balance,
            player?.betConfirmationValue
          )) ||
          isBetGreaterthanSetValue(currentBet, player.balance, 95))
      ) {
        setConfirmation(true);
        return;
      }
      if (!askConfirmation && dontShowConfirmation) {
        let payload: Partial<User> = {
          isBetConfirmation: false,
          betConfirmationValue: 0,
        };
        dispatch(updateSettings(payload));
      }

      setIsRestart(true);
      setRevealedCells([]);
      setGrid(
        Array(5)
          .fill(0)
          .map(() => Array(5).fill("💎"))
      );

      dispatch(updateLoading());
      playBetSound();

      tokenTypeRef.current = tokenType;
      if (playType === DICEMODE.MANUAL) {
        const gameData = { bet: currentBet, numOfMines, tokenType, playType };

        socket?.emit(PLAY_MINES_GAME, {
          accessToken,
          gameData,
        });
      }

      if (playType === DICEMODE.AUTO) {
        betRef.current = currentBet;
        if (!isAutoPlaying) {
          setAutoPlayStatesRef({
            ...autoPlayStates,
            stopNetGain: 0,
            stopNetLoss: 0,
          });
          betRef.current = currentBet;
        }
        setIsAutoPlaying(true);
        const gameData = {
          bet: currentBet,
          numOfMines,
          tokenType,
          playType,
          autoSelection,
        };

        socket?.emit(PLAY_MINES_GAME, {
          accessToken,
          gameData,
        });
      }

      return;
    }
  };

  const handlePayout = () => {
    if (loading) return;
    dispatch(updateLoading());
    socket?.emit(PAYOUT_MINES_GAME, {
      accessToken,
    });
  };

  const isBalanceError = !accessToken;

  const handleRemoveModal = () => {
    if (isWin) {
      dispatch(resetGame());
    }
  };

  const getNextPayout = RoundProfitOnWin(
    getPayout(mines, revealedCells.length + 1) * bet
  );

  const disableButtons = !accessToken || gameStatus === GAMESTATUS.INGAME;
  useEffect(() => {
    if (
      mineGame &&
      mineGame?.openTiles?.length &&
      openTilesRef.current !== mineGame?.openTiles?.length
    ) {
      const isBomb = mineGame?.openTiles.find((tile) => tile.type === "mine");
      if (isBomb) {
        playMineSound();
      } else {
        playDiamondSound();
      }
      openTilesRef.current = mineGame?.openTiles?.length;
    }
  }, [mineGame]);

  const handleClick = (columnIndex: number, rowIndex: number) => {
    if (isAutoPlaying) return;
    if (playType === DICEMODE.AUTO) {
      setAutoSelection((prevSelection) => {
        const newItem = `${columnIndex},${rowIndex}`;
        if (prevSelection.includes(newItem)) {
          return prevSelection.filter((item) => item !== newItem);
        } else {
          if (25 < autoSelection.length + numOfMines + 1) return prevSelection;

          return [...prevSelection, newItem];
        }
      });
    } else {
      handleCellClick(columnIndex, rowIndex);
    }
  };

  const handleReset = () => {
    dispatch(resetGame());
    setIsRestart(true);
    setRevealedCells([]);
    setGrid(
      Array(5)
        .fill(0)
        .map(() => Array(5).fill("💎"))
    );
  };

  useEffect(() => {
    handleReset();
  }, [playType]);

  const handleNumberOfMines = (selected: number) => {
    setNumOfMines(selected);
    setAutoSelection([]);
  };

  const handleStopAutoPlaying = () => {
    setIsAutoPlaying(false);
    handleReset();
  };

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        title="Bet Confirmation"
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          handleCreateGame(false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet || 0,
          player.balance,
          95
        )}
      />
      <Stack gap={2} onClick={() => handleRemoveModal()}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Mines
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack>
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                sx={{
                  display: { md: "none", sm: "block", xs: "block" },
                  width: "100%",
                }}
              >
                <Buttons
                  accessToken={accessToken}
                  gameStatus={gameStatus}
                  revealedCells={revealedCells}
                  disabled={!toggle}
                  handlePayout={handlePayout}
                  payout={payout}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  tokenType={mineGame.tokenType}
                  stopPlaying={() => handleStopAutoPlaying()}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={disableButtons || isAutoPlaying}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.MINES}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={disableButtons}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  balance={player.balance}
                  multiplier={numOfMines}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                    disabled={disableButtons || isAutoPlaying}
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    disabled={disableButtons || isAutoPlaying}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1}>
                <MinesAutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={isAutoPlaying}
                  currentNumberOfGames={autoPlayStatesRef.numberOfGames}
                />
                <Typography color="text.primary">Mines</Typography>
                <Select
                  value={numOfMines}
                  onChange={(e) => handleNumberOfMines(Number(e.target.value))}
                  input={
                    <InputFieldStyled
                      disabled={disableButtons}
                      sx={{
                        "& .MuiInputBase-input": {
                          "&.Mui-disabled": {
                            backgroundColor: "#2F5272",
                            WebkitTextFillColor: "black",
                          },
                        },
                      }}
                    />
                  }
                  disabled={disableButtons || isAutoPlaying}
                  MenuProps={MenuProps}
                >
                  {listOfMines.map((mine: number) => (
                    <MenuItem value={mine}>{mine}</MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <Buttons
                  accessToken={accessToken}
                  gameStatus={gameStatus}
                  revealedCells={revealedCells}
                  handlePayout={handlePayout}
                  payout={payout}
                  disabled={
                    !toggle ||
                    (playType === DICEMODE.AUTO && autoSelection.length === 0)
                  }
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  tokenType={mineGame.tokenType}
                  stopPlaying={() => handleStopAutoPlaying()}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              {!toggle && (
                <Typography variant="h6" color="error">
                  {DEFAULT_DISABLED_MESSAGE}
                </Typography>
              )}
            </Stack>
            <GameOptions game={GAMES.MINES} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
          >
            {playType === DICEMODE.MANUAL && (
              <div className={styles.grid}>
                {isWin && (
                  <WinModal
                    payout={payout}
                    multiplier={multiplier}
                    tokenType={mineGame.tokenType}
                  />
                )}
                {grid.map((row, rowIndex) => (
                  <>
                    {row.map((cell, columnIndex) => {
                      const key = `${columnIndex},${rowIndex}`;
                      const isRevealed = revealedCells.includes(key);
                      const checkIfLastCell = lastCellHitRef.current === key;
                      const checkIfBomb = mineLocations?.includes(key);
                      const isLastBomb = checkIfBomb && checkIfLastCell;
                      const isAutoSelected = false;
                      return (
                        <Tile
                          handleClick={() => {
                            handleClick(columnIndex, rowIndex);
                          }}
                          index={columnIndex + rowIndex}
                          isRevealed={isRevealed}
                          cellType={cell}
                          inPlay={gameStatus === GAMESTATUS.INGAME || loading}
                          gameStatus={gameStatus}
                          payout={getNextPayout}
                          isRestart={isRestart}
                          tokenType={mineGame.tokenType}
                          isLastBomb={isLastBomb || false}
                          isAuto={false}
                          isAutoSelected={isAutoSelected}
                        />
                      );
                    })}
                  </>
                ))}
              </div>
            )}
            {playType === DICEMODE.AUTO && (
              <div className={styles.grid}>
                {isWin && (
                  <WinModal
                    payout={payout}
                    multiplier={multiplier}
                    tokenType={mineGame.tokenType}
                    duration={0.25}
                  />
                )}
                {grid.map((row, rowIndex) => (
                  <>
                    {row.map((cell, columnIndex) => {
                      const key = `${columnIndex},${rowIndex}`;
                      const isRevealed = revealedCells.includes(key);
                      const checkIfBomb = mineLocations?.includes(key);
                      const isAutoSelected = autoSelection.includes(key);
                      const isLastBomb = checkIfBomb && isAutoSelected;
                      return (
                        <TilesAuto
                          handleClick={() => {
                            handleClick(columnIndex, rowIndex);
                          }}
                          index={columnIndex + rowIndex}
                          isRevealed={isRevealed}
                          cellType={cell}
                          inPlay={gameStatus === GAMESTATUS.INGAME || loading}
                          gameStatus={gameStatus}
                          payout={getNextPayout}
                          isRestart={isRestart}
                          tokenType={mineGame.tokenType}
                          isLastBomb={isLastBomb || false}
                          isAuto={true}
                          isAutoSelected={isAutoSelected}
                          isAutoPlaying={isAutoPlaying}
                        />
                      );
                    })}
                  </>
                ))}
              </div>
            )}
            {!autoSelection.length && playType === DICEMODE.AUTO && (
              <Stack sx={{ width: "100%", pt: 2 }} alignItems="center">
                <Typography variant="h6" color="text.primary">
                  Select 1 - {25 - numOfMines} tiles to begin
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Mines;
