import {
  Stack,
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { theme } from "../theme";
import { IMessage, MESSAGETYPE, USERTYPE } from "../../types/index.d";
import { getPlayerImg, getPlayerLevelData } from "../../utils";
import { UserTypeIcon } from "./icons";
import LogoImgMobile from "../../assets/runewagerMobile.svg";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";
import { GAMES, GAMESICONS } from "../../constants/games";
import styles from "./index.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatContext from "../../contexts/chat/context";
import {
  ADMIN_DELETE_MESSAGE_USER,
  ADMIN_MUTE_USER,
} from "../../constants/socket";
import { useAppSelector } from "../store/hooks";
import { selectToken } from "../slices/userSlice";
import { chatDictionary, parseHiddenUsername } from "../../constants";
interface ITextBubble {
  index: number;
  userName: string;
  message: IMessage;
  renderTimeDifference: any;
  rank?: number;
  userType?: string;
  userId?: string;
  id?: string;
  handleToggle?: any;
}

const getAvatar = (
  imgSrc: string,
  isOwner: boolean,
  messageType: MESSAGETYPE,
  game: any
) => {
  if (messageType === MESSAGETYPE.GAMES) {
    return GAMESICONS[game as GAMES]?.img || "";
  }

  if (messageType === MESSAGETYPE.LEVELUP) {
    return getPlayerImg(game)?.imgSrc || "";
  }
  return isOwner ? LogoImgMobile : imgSrc;
};

export const isModUser = (userType: string) => {
  return (
    userType === USERTYPE.ADMIN ||
    userType === USERTYPE.OWNER ||
    userType === USERTYPE.CASHIER
  );
};

const getTitle = (userType: string) => {
  if (userType === USERTYPE.CASHIER) {
    return "Cashier";
  }
  return "Owner";
};

const getUserName = (messageType: MESSAGETYPE, userName: string) => {
  if (messageType === MESSAGETYPE.GAMES) {
    return userName.replaceAll("_", " ");
  }

  if (messageType === MESSAGETYPE.LEVELUP) {
    return "LEVEL UP";
  }
  return userName;
};

function containsOnlyEmojis(str: string) {
  // Regular expression to match emojis
  const emojiRegex =
    /[\uD83C-\uDBFF\uDC00-\uDFFF\u2600-\u27BF\uFE0F\u1F900-\u1F9FF\u1FA70-\u1FAFF]/;

  // Split the string into an array of characters and check if each is an emoji
  return Array.from(str).every((char) => emojiRegex.test(char));
}

export const TextBubble = (props: ITextBubble) => {
  const {
    index,
    userName,
    id,
    message,
    renderTimeDifference,
    rank,
    userType = "User",
    userId = "",
    handleToggle,
  } = props;
  const getPlayerLevel = getPlayerLevelData(rank);
  const { socket } = useContext(ChatContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const accessToken = useAppSelector(selectToken);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMute = () => {
    const isMod = isModUser(userType);
    if (isMod) {
      socket?.emit(ADMIN_MUTE_USER, {
        id: userId,
        accessToken,
      });
    }
  };

  const handleDel = () => {
    const isMod = isModUser(userType);
    if (isMod) {
      socket?.emit(ADMIN_DELETE_MESSAGE_USER, {
        id: id,
        accessToken,
      });
    }
  };

  const isAdmin = isModUser(userType);

  const isOwner =
    message?.userType === "ADMIN" ||
    message?.userName === "Automated Message" ||
    message?.userType === USERTYPE.CASHIER;

  const isGame =
    message.type === MESSAGETYPE.GAMES || message.type === MESSAGETYPE.LEVELUP;
  const regex = /@(\w+)/;

  const match = message.text.match(regex);
  let isUserTagged = false;
  if (match) {
    isUserTagged =
      match[1].toLocaleLowerCase() === userName.toLocaleLowerCase();
  }

  const handleNavigate = () => {
    if (
      message.userName === GAMES.SLIDE &&
      message.text.includes("Bonus round has started")
    ) {
      navigate("/game/slide");
      if (!matchesMD) {
        handleToggle(false);
      }
    }
  };

  const handleMessage = () => {
    if (isGame) {
      return (
        <Typography
          sx={{
            textWrap: "pretty",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
          }}
          color={theme.palette.text.disabled}
          // dangerouslySetInnerHTML={{
          //   __html: formatMessage(message.text, message.type),
          // }}
        >
          {formatMessage(message.text, isAdmin)}
        </Typography>
      );
    }

    if (message.text.includes('{"url":"https://')) {
      try {
        const previewData = JSON.parse(message.text);
        return (
          <img
            src={previewData.url}
            alt={previewData.url}
            style={{ width: "80%" }}
          />
        );
      } catch {
        return null;
      }
    }

    if (containsOnlyEmojis(message.text) && message.text.length < 7) {
      return (
        <Typography
          sx={{
            textWrap: "pretty",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
            fontSize: "2em",
          }}
          color={theme.palette.text.disabled}
        >
          {createLinkedMessage(message.text, handleToggle, matchesMD)}
        </Typography>
      );
    }

    return (
      <Typography
        sx={{
          textWrap: "pretty",
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        }}
        color={theme.palette.text.disabled}
      >
        {createLinkedMessage(message.text, handleToggle, matchesMD)}
      </Typography>
    );
  };
  const messageData = handleMessage();

  if (!messageData) {
    return null;
  }
  return (
    <ListItem
      key={index}
      onClick={() => handleNavigate()}
      className={message?.userName === "RAIN" ? styles.gameBorder : ""}
      sx={{
        m: 1,
        // mr: 0,
        borderRadius: "8px",
        backgroundColor: userName === message.userName ? "#273F55" : "#213240",
        alignItems: "flex-start",
        userSelect: "text",
        boxShadow: isUserTagged
          ? "inset 0 0 5px 0 #ffffffb5"
          : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
      }}
      secondaryAction={
        isModUser(userType) ? (
          <Box sx={{ display: isModUser(userType) ? "block" : "none" }}>
            <IconButton
              onClick={(e) => handleClick(e)}
              edge="end"
              aria-label="delete"
              sx={{ color: "white" }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              disableScrollLock={true}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleMute}>Mute</MenuItem>
              <MenuItem onClick={handleDel}>Delete</MenuItem>
            </Menu>
          </Box>
        ) : null
      }
    >
      <ListItemAvatar sx={{ position: "relative", mt: 1 }}>
        <Avatar
          variant="square"
          src={getAvatar(
            getPlayerLevel.imgSrc,
            isOwner,
            message.type,
            message.userName
          )}
          sx={{
            "& .MuiAvatar-img": {
              objectFit: "contain",
            },
          }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Stack direction="row" gap={0.25} alignItems="center">
                {message.userType && (
                  <Tooltip
                    title={
                      isOwner ? getTitle(message?.userType) : message.userType
                    }
                    arrow
                    placement="bottom"
                  >
                    <Stack>
                      <UserTypeIcon userType={message.userType} />
                    </Stack>
                  </Tooltip>
                )}
                <Typography>
                  {getUserName(message.type, message.userName)}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              color={theme.palette.text.disabled}
              sx={{ opacity: 0.7, fontSize: "0.75em" }}
            >
              {message?.createdAt && renderTimeDifference(message?.createdAt)}
            </Typography>
          </Stack>
        }
        secondary={messageData}
      />
    </ListItem>
  );
};

const createLinkedMessage = (
  text: string,
  handleToggle: any,
  matchesMD: boolean
) => {
  const regex = new RegExp(
    `(${chatDictionary.map((item) => item.command).join("|")})`,
    "g"
  );
  const parts = text.split(regex);

  const handleLinkClick = (e: any) => {
    if (!matchesMD) {
      handleToggle(false);
    }
  };

  return parts.map((part, index) => {
    const commandObj = chatDictionary.find((item) => item.command === part);
    if (commandObj) {
      if (commandObj?.isDiff) {
        return (
          <Link
            key={index}
            to={commandObj.path}
            style={{ color: "#33EC3D" }}
            target="_blank"
            onClick={handleLinkClick}
            rel="noopener noreferrer"
          >
            {commandObj.command}
          </Link>
        );
      } else {
        return (
          <Link
            key={index}
            to={commandObj.path}
            style={{ color: "#33EC3D" }}
            onClick={handleLinkClick}
          >
            {commandObj.command}
          </Link>
        );
      }
    }
    return part;
  });
};

const formatMessage = (text: string, isAdmin: boolean) => {
  const newMessage = parseHiddenUsername(text, isAdmin);
  const html = newMessage.replace(
    "[TOKEN]",
    `<img src=${RWTokenIcon}  alt="Token Image" style="width: 1.5em; vertical-align: middle;"/>`
  );
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
