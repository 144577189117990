import {
  Stack,
  Typography,
  Box,
  Link,
  IconButton,
  Slider,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ModalComponent from "@mui/material/Modal";
import { theme } from "../theme";
import { useState } from "react";
import { GAMES, getGameData } from "../../constants/games";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import {
  addFavourite,
  selectFavouriteGames,
  selectIsFavouriteLoading,
  selectVolume,
  updateVolume,
} from "../slices/userSlice";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GradeIcon from "@mui/icons-material/Grade";
import { useNavigate } from "react-router-dom";
import { Button } from "../button";
export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 300, md: 350 },
  zIndex: 10,
  p: 4,
  borderRadius: 4,
};

export const FavouriteButton = ({ game }: { game: string }) => {
  const dispatch = useDispatch<AppDispatch>();

  const favouriteGames = useSelector(selectFavouriteGames);
  const favouriteGamesLoading = useSelector(selectIsFavouriteLoading);
  const handleUpdateFavourite = () => {
    dispatch(addFavourite({ game }));
  };

  const isFav = favouriteGames.includes(game);
  return (
    <IconButton
      onClick={() => handleUpdateFavourite()}
      disabled={favouriteGamesLoading}
      sx={{ color: "white" }}
    >
      {isFav ? <GradeIcon /> : <StarBorderIcon />}
    </IconButton>
  );
};
export const GameOptions = ({
  game,
  showStop = false,
  handleStopAuto,
}: {
  game: GAMES;
  showStop?: boolean;
  handleStopAuto?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [openVolume, setOpenVolume] = useState(false);
  const navigate = useNavigate();
  const volume = useSelector(selectVolume);

  const handleTooltipClose = () => {
    setOpenVolume(false);
  };

  const handleTooltipOpen = () => {
    setOpenVolume(true);
  };

  const gameData = getGameData(game);
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <ShortDescModal setOpen={setOpen} open={open} game={game} />
      <Link
        color="text.primary"
        onClick={() => {
          navigate(`/fairness/${gameData.url}`);
        }}
      >
        Fairness
      </Link>
      <Stack direction="row">
        {showStop ? (
          <Button
            variantType="error"
            onClick={() => {
              console.log("stoppp");
              if (handleStopAuto) {
                console.log("stoppp");
                handleStopAuto();
              }
            }}
          >
            Stop
          </Button>
        ) : null}

        <FavouriteButton game={game} />
        <IconButton onClick={() => setOpen(true)} sx={{ color: "white" }}>
          <InfoOutlinedIcon />
        </IconButton>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              placement="top"
              onClose={handleTooltipClose}
              open={openVolume}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<VolumeSlider volume={volume} />}
            >
              <IconButton onClick={handleTooltipOpen} sx={{ color: "white" }}>
                {volume ? <VolumeUpOutlinedIcon /> : <VolumeOffOutlinedIcon />}
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </Stack>
    </Stack>
  );
};

const ShortDescModal = (props: any) => {
  const { open, setOpen, game } = props;
  const gameData = getGameData(game);

  return (
    <ModalComponent
      open={open}
      onClose={() => setOpen(false)}
      disableScrollLock={true}
    >
      <Box sx={modalStyle}>
        <Stack gap={2}>
          <Stack>
            <Typography variant="h4" fontWeight={600} color="text.primary">
              {gameData.title}
            </Typography>
            <Typography
              color="text.primary"
              fontSize={16}
              sx={{ textDecoration: "underline" }}
            >
              How to play
            </Typography>
          </Stack>

          <Typography
            color="text.primary"
            fontSize={16}
            dangerouslySetInnerHTML={{ __html: gameData.shortDesc }}
          />
        </Stack>
      </Box>
    </ModalComponent>
  );
};

const VolumeSlider = ({ volume }: { volume: number }) => {
  const dispatch = useDispatch<AppDispatch>();
  function preventHorizontalKeyboardNavigation(event: React.KeyboardEvent) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleVolumeChange = (_event: any, volume: any) => {
    dispatch(updateVolume(volume));
  };

  return (
    <Slider
      sx={{
        minHeight: "100px",
        my: 2,
        '& input[type="range"]': {
          WebkitAppearance: "slider-vertical",
        },
      }}
      color="secondary"
      onChangeCommitted={handleVolumeChange}
      orientation="vertical"
      defaultValue={volume}
      valueLabelDisplay="auto"
      onKeyDown={preventHorizontalKeyboardNavigation}
    />
  );
};
