// ALL CHAT
export const MESSAGE_ALL_CHAT = "message-all";
export const GET_NEW_MESSAGE_ALL_CHAT = "new-message";
export const NEW_MESSAGE_ALL = "new-message-all";
export const ALL_USER_COUNT = "users-count";

//ADMIN CHAT
export const NEW_MESSAGE_TO_ADMIN = "new-message-to-admin";
export const ORDER_CREATED_TO_ADMIN = "order-created-to-admin";
export const UPDATE_ORDER_STATUS_TO_ADMIN = "update-order-status-to-admin";
export const USER_MESSAGE_ON_ADMIN_CHAT = "new-message-admin";
export const ADMIN_READ_AT_UPDATE_TO_ADMIN = "admin-read-at-update-to-admin";
export const ADMIN_READ_AT_UPDATE = "admin-read-at-update";

export const ADMIN_MUTE_USER = "admin-mute-user";
export const ADMIN_DELETE_MESSAGE_USER = "admin-delete-message-user";
export const EMIT_DELETED_MESSAGE = "emite-delete-message";

//ORDER UPDATE
export const ORDER_UPDATE_BY_ADMIN = "order-update-by-admin";

// UPDATE
export const UPDATE_LEVEL = "upate-level";

// UPDATEBALANCE
export const UPDATE_BALANCE = "upate-balance";

//User
export const UPDATE_USER = "upate-user";

//MINE GAME
export const PLAY_MINES_GAME = "play-mines-game";
export const PAYOUT_MINES_GAME = "payout-mines-game";
export const EMIT_MINES_PAYOUT_GAME = "emit-mines-payout-game";
export const CHECK_MINES_GAME = "check-mines-game";
export const EMIT_CHECK_MINES_GAME = "emit-check-mines-game";
export const EMIT_MINES_GAME = "emit-mines-game";

//DICE GAME
export const PLAY_DICE_GAME = "play-dice-game";
export const EMIT_DICE_GAME = "emit-dice-game";

//LIMBO GAME
export const PLAY_LIMBO_GAME = "play-limbo-game";
export const EMIT_LIMBO_GAME = "emit-limbo-game";

//COIN FLIP GAME
export const PLAY_COIN_FLIP_GAME = "play-coin-flip-game";
export const EMIT_COIN_FLIP_GAME = "emit-coin-flip-game";

//PLINKO GAME
export const PLAY_PLINKO_GAME = "play-plinko-game";
export const EMIT_PLINKO_GAME = "emit-plinko-game";

//KENO GAME
export const PLAY_KENO_GAME = "play-keno-game";
export const EMIT_KENO_GAME = "emit-keno-game";

//DUEL GAME
export const PLAY_DUEL_GAME = "play-duel-game";
export const UPDATE_DUEL_GAME = "update-duel-game";
export const EMIT_DUEL_GAME = "emit-duel-game";
export const UPDATE_DUEL_STAKER = "update-duel-staker";
export const EMIT_DUEL_STAKER = "emit-duel-staker";
export const CANCEL_DUEL_GAME = "cancel-duel-game";
export const REMOVE_ALLOCATION_DUEL_GAME = "remove-allocation-duel-game";

//SLIDE GAME
export const REQUEST_SLIDE_DATA = "request-slide-data";
export const RECEIVE_SLIDE_DATA = "slide-data-response";
export const REQUEST_SLIDE_TIMER = "slide-timer";
export const REQUEST_SLIDE_RESULT = "slide-result";
export const PLAY_SLIDE_GAME = "play-slide-game";
export const PLAY_SLIDE_AUTO_GAME = "play-slide-auto-game";
export const EMIT_SLIDE_BET = "emit-slide-bet-result";

//CONSTANTS
export const UPDATE_CONSTANTS = "update-constants";

//GAME FEED
export const UPDATE_ALL_GAME_FEED = "update-all-game-feed";
export const UPDATE_HIGH_ROLLER_FEED = "update-high-roller-feed";
export const UPDATE_MY_BETS_FEED = "update-my-bets-feed";

//Reconnect
export const RECONNECT_EMIT_UPDATES = "reconnect-emit-updates";
export const RECONNECT_GET_UPDATES = "reconnect-get-updates";

//Blackjack
export const PLAY_BLACKJACK_GAME = "play-blackjack-game";
export const EMIT_BLACKJACK_GAME = "emit-blackjack-game";
export const CHECK_BLACKJACK_GAME = "check-blackjack-game";
export const EMIT_CHECK_BLACKJACK_GAME = "emit-check-blackjack-game";
//Rain
export const CHAT_RAIN_UPDATE = "chat-rain-update";

//hilo
export const PLAY_HILO_GAME = "play-hilo-game";
export const EMIT_HILO_GAME = "emit-hilo-game";
export const CHECK_HILO_GAME = "check-hilo-game";
export const PAYOUT_HILO_GAME = "payout-hilo-game";
export const EMIT_CHECK_HILO_GAME = "emit-check-hilo-game";
