import {
  Card,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { GAMES, getGameData } from "../../constants/games";
import {
  selectGameHighRollerFeed,
  selectGameLuckyWinFeed,
} from "../slices/gameFeeds";
import { RootState } from "../store";
import { useAppSelector } from "../store/hooks";
import FeedTable from "./feedTable";

enum FEEDSELECTION {
  NULL,
  HIGHROLLER,
  LUCKYWIN,
  DESCRIPTION,
}

export const getColor = (isTrue: boolean) => {
  if (isTrue) {
    return "#33EC3D !important";
  }
  return "grey !important";
};

export const getBg = (isTrue: boolean) => {
  if (isTrue) {
    return "#213240 !important";
  }
  return "transparent";
};
export const DescWrapper = ({
  game,
  showLuckyWins = true,
}: {
  game: GAMES;
  showLuckyWins?: boolean;
}) => {
  const [selection, setSelection] = useState(0);
  const [isEven, setIsEven] = useState(0);
  const highRollerFeed = useAppSelector((state: RootState) =>
    selectGameHighRollerFeed(state, game)
  );
  const highRollerFeedRef = useRef(highRollerFeed);

  const luckyWinFeed = useAppSelector((state: RootState) =>
    selectGameLuckyWinFeed(state, game)
  );
  const [isEvenLuckyWin, setIsEvenLuckyWin] = useState(0);
  const luckyWinFeedRef = useRef(luckyWinFeed);

  useEffect(() => {
    if (
      highRollerFeedRef.current?.length &&
      highRollerFeed?.length &&
      highRollerFeedRef.current[0]._id !== highRollerFeed[0]._id
    ) {
      setIsEven((prev) => (prev === 0 ? 1 : 0));

      highRollerFeedRef.current = highRollerFeed;
    }
  }, [highRollerFeed]);

  useEffect(() => {
    if (
      luckyWinFeedRef.current?.length &&
      luckyWinFeed?.length &&
      luckyWinFeedRef.current[0]._id !== luckyWinFeed[0]._id
    ) {
      setIsEvenLuckyWin((prev) => (prev === 0 ? 1 : 0));
      luckyWinFeedRef.current = luckyWinFeed;
    }
  }, [luckyWinFeed]);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: number
  ) => {
    setSelection(Number(newAlignment));
    // setSelection(null);
  };
  return (
    <Card sx={{ p: 1, my: 3 }}>
      <Stack>
        <ToggleButtonGroup
          size="medium"
          value={selection}
          exclusive
          sx={{ boxShadow: 3 }}
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            sx={{
              width: "100%",
              color: getColor(selection === FEEDSELECTION.HIGHROLLER),
            }}
            value={FEEDSELECTION.HIGHROLLER}
          >
            HighRollers
          </ToggleButton>
          {showLuckyWins && (
            <ToggleButton
              sx={{
                width: "100%",
                color: getColor(selection === FEEDSELECTION.LUCKYWIN),
              }}
              value={FEEDSELECTION.LUCKYWIN}
            >
              Lucky Win
            </ToggleButton>
          )}
          <ToggleButton
            sx={{
              width: "100%",
              color: getColor(selection === FEEDSELECTION.DESCRIPTION),
            }}
            value={FEEDSELECTION.DESCRIPTION}
          >
            Description
          </ToggleButton>
        </ToggleButtonGroup>
        <Stack sx={{ p: 1, pt: 0 }}>
          {selection === FEEDSELECTION.HIGHROLLER && (
            <FeedTable feed={highRollerFeed || []} isEven={isEven} />
          )}

          {selection === FEEDSELECTION.LUCKYWIN && (
            <FeedTable feed={luckyWinFeed || []} isEven={isEvenLuckyWin} />
          )}

          {selection === FEEDSELECTION.DESCRIPTION && (
            <DescriptionWrapper game={game} />
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
const depositTextSample = `<subheading style="font-size: ${23}px;">Contrary to popular belief,</subheading> Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.<br/><br/>
  The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.`;

const formattedText = (name: string) => `
Depositing funds into your account on Runewager's social casino platform is a straightforward process. Here's how you can do it:
<ol style="list-style-type: decimal; "> 
  <li> In-game Trading: One of the simplest methods to deposit on Runewager is by trading OSRS or RS3 gold within the game. Initiate your deposit request, and our trusted cashiers will provide you with a designated meeting spot in-game where you can transfer your in-game gold to Runewager.  </li>
  <li> Cryptocurrency: Another convenient option for depositing funds is through cryptocurrency. When making your deposit, select the cryptocurrency option and choose from a variety of options including BTC (Bitcoin), LTC (Litecoin), ETH (Ethereum), and more. If your preferred cryptocurrency isn't listed, select the other option on the dropdown and communicate with the cashier to see if they will accept it.  </li>
</ol>
`;
const DescriptionWrapper = ({ game }: { game: GAMES }) => {
  const gameData = getGameData(game);
  const { title, desc, edge, gif, img } = gameData;
  const depositTitle = `How to Deposit on Runewager for ${title}`;
  const depositText = formattedText(title);

  return (
    <Box sx={{ my: 3 }}>
      <Stack direction={{ md: "row", sm: "column" }}>
        {/* <Stack
          sx={{
            flex: 0.75,
            height: "50%",
            marginRight: { md: 2, sm: 0 },
            // float: { md: "left", sm: "top" },
          }}
        > */}
        <Box
          sx={{
            flex: 0.35,
            width: "100%",
            float: { md: "left", sm: "top" },
            marginRight: { md: 2, sm: 0 },
          }}
        >
          <img
            style={{
              width: "100%",
              height: "auto",
              // objectFit: "cover",
              borderRadius: 10,
            }}
            src={gif}
          />
        </Box>

        {/* </Stack> */}
        <Stack sx={{ flex: 1 }} gap={1}>
          <Stack
            direction="row"
            gap={1}
            flexWrap="wrap"
            alignItems="center"
            sx={{ my: { md: 0, sm: 1, xs: 1 } }}
          >
            <Typography variant="h5">{title}</Typography>
            <Chip label={`RTP: ${edge}`} variant="outlined" />
            {gameData?.risk && (
              <Chip label={gameData?.risk} variant="outlined" />
            )}
          </Stack>
          <Typography
            sx={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: desc }}
          />
        </Stack>
      </Stack>

      <Stack>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          {depositTitle}
        </Typography>
        <Typography
          sx={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{ __html: depositText }}
        />
      </Stack>
    </Box>
  );
};
