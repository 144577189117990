import { Stack, IconButton, useMediaQuery } from "@mui/material";
import { theme } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ORDERTYPES } from "../../types/index.d";
import { Button } from "../button";

interface IModal {
  buttonData: any[];
  changeModalType: (type: any) => void;
  modalType: string;
  isBackButton?: boolean;
  handleBack?: () => void;
}

const SelectionComponent = ({
  changeModalType,
  buttonData,
  modalType,
  isBackButton,
  handleBack,
}: IModal) => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Stack direction="row" gap={2}>
        {isBackButton && handleBack && (
          <IconButton
            sx={{
              position: "absolute",
              transform: matchesMD
                ? "translate(-120%,0%)"
                : "translate(-100%,0%)",
              color: theme.palette.text.primary,
            }}
            onClick={() => handleBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        {buttonData.map((button) => (
          <Button
            sx={{
              width: "100px",
              fontWeight: 400,
              p: 1,
              borderRadius: "5px",
            }}
            variantType={modalType === button.type ? "selected" : "selection"}
            onClick={() => changeModalType(button.type)}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
    </>
  );
};

export default SelectionComponent;
