import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import {
  EXCHANGETYPE,
  IExchangeDataType,
  ORDERTYPES,
} from "../../types/index.d";
import SelectionComponent from "../selectionComponent";
import {
  CryptoOptions,
  CryptoType,
  exchangeData,
} from "../../constants/exchange";
import { Button } from "../button";
import { theme } from "../theme";
import { TokenIcon } from "../tokenIcon";
import { useEffect, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { selectCryptoToggle, selectToken } from "../slices/userSlice";
import ExchangeService from "../../services/exchange";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { clearErrorMessage } from "../slices/orderSlice";

import BTCIcon from "../../assets/crypto/btc-logo.svg";
import ETHIcon from "../../assets/crypto/ethereum-eth-logo.svg";
import LTCIcon from "../../assets/crypto/litecoin-ltc-logo.svg";
import SOLIcon from "../../assets/crypto/solana-sol-logo.svg";
import RuneScapeIcon from "../../assets/runeScapeCoin.png";

export const FunCashBanner = () => (
  <Stack
    sx={{
      position: "absolute",
      bottom: 0,
      width: "100%",
      bgcolor: theme.palette.success.main,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    }}
    alignItems="center"
    direction="row"
    justifyContent="center"
    gap={1}
  >
    <Typography color="text.secondary" fontWeight={600}>
      Free Sweep Cash
    </Typography>
    <TokenIcon />
  </Stack>
);
interface IExchangeOptions {
  setSelectedType: (type: IExchangeDataType | null) => void;
  currentType: ORDERTYPES;
  setCurrentType: (type: ORDERTYPES) => void;
  disabled: boolean;
  rs3Value?: number;
  osrsValue?: number;
  setCryptoType: any;
}

const ExchangeSelection = [
  {
    type: ORDERTYPES.DEPOSIT,
    label: "Purchase",
  },
  {
    type: ORDERTYPES.WITHDRAWAL,
    label: "Redeem",
  },
  // {
  //   type: ORDERTYPES.PROMOCODE,
  //   label: "Promo Code",
  // },
];

// With Fees
// function goldToDollars(value = 0, isWithdraw = false) {
//   if (isWithdraw) {
//     return (value * 1.05).toFixed(3);
//   }
//   return (value * 0.95).toFixed(3);
// }

// function dollarsToGolds(value = 0, isWithdraw = false) {
//   const dollarsPerToken = 1 / 4;
//   if (isWithdraw) {
//     return ((dollarsPerToken / value) * 0.95).toFixed(2);
//   }
//   return ((dollarsPerToken / value) * 1.05).toFixed(2);
// }

function goldToDollars(value = 0, isWithdraw = false) {
  if (isWithdraw) {
    return value.toFixed(3);
  }
  return value.toFixed(3);
}

function dollarsToGolds(value = 0, isWithdraw = false) {
  const dollarsPerToken = 1 / 4;
  if (isWithdraw) {
    return (dollarsPerToken / value).toFixed(2);
  }
  return (dollarsPerToken / value).toFixed(2);
}

const DepositModal = ({
  setSelectedType,
  setCurrentType,
  rs3Value,
  osrsValue,
  setCryptoType,
}: IExchangeOptions) => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  const dispatch = useDispatch<AppDispatch>();
  // const toggleDeposits = useAppSelector(selectCryptoToggle);
  const handleCryptoUpdate = (type: string) => {
    setCryptoType(type);
    setSelectedType(exchangeData[2]);
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, []);

  return (
    <Stack gap={{ md: 1, xs: 0.5, width: "100%" }} direction="column">
      <Stack
        direction={{ md: "row", sm: "column", xs: "column" }}
        flexWrap={{ md: "wrap", xs: "nowrap" }}
        gap={1}
      >
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: 0.5,
            pb: 3,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", xs: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
          }}
          onClick={() => setSelectedType(exchangeData[0])}
        >
          <Stack direction="row" gap={1}>
            <Typography
              variant={matchesMD ? "h5" : "body1"}
              color="text.primary"
              sx={{ textWrap: "nowrap" }}
            >
              OSRS GOLD
            </Typography>
            <img
              alt={"RuneScapeIcon"}
              src={RuneScapeIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>

          <Stack
            alignItems="center"
            direction={{ md: "row", sm: "column", xs: "column" }}
          >
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              <TokenIcon freeCash />
              10000 = {dollarsToGolds(osrsValue) || 0}m
            </Typography>
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              ({goldToDollars(osrsValue)}$/m)
            </Typography>
          </Stack>
          <FunCashBanner />
        </Button>
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: 0.5,
            pb: 3,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
          }}
          onClick={() => setSelectedType(exchangeData[1])}
        >
          <Stack direction="row" gap={1}>
            <Typography
              variant={matchesMD ? "h5" : "body1"}
              color="text.primary"
              sx={{ textWrap: "nowrap" }}
            >
              RS3 GOLD
            </Typography>
            <img
              alt={"RuneScapeIcon"}
              src={RuneScapeIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            direction={{ md: "row", sm: "column", xs: "column" }}
          >
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              <TokenIcon freeCash />
              10000 = {dollarsToGolds(rs3Value) || 0}m
            </Typography>
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              ({goldToDollars(rs3Value)}$/m)
            </Typography>
          </Stack>
          <FunCashBanner />
        </Button>

        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            pt: 1,
            pb: 3,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
          }}
          onClick={() => handleCryptoUpdate(CryptoType.BTC)}
        >
          <Stack direction="row" gap={1}>
            <img
              alt={"BTC"}
              src={BTCIcon}
              style={{
                width: "2em",
              }}
            />
            <img
              alt={"LTC"}
              src={LTCIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <Typography
            variant={matchesMD ? "h5" : "body1"}
            color="text.primary"
            sx={{ textWrap: "nowrap", mx: 1 }}
          >
            Crypto
          </Typography>
          <Stack direction="row" gap={1}>
            <img
              alt={"ETH"}
              src={ETHIcon}
              style={{
                width: "2em",
              }}
            />
            <img
              alt={"SOL"}
              src={SOLIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <FunCashBanner />
        </Button>
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
          }}
          onClick={() => setCurrentType(ORDERTYPES.REDEEMGIFT)}
        >
          <Typography
            variant={matchesMD ? "h5" : "body1"}
            color="text.primary"
            sx={{ textWrap: "nowrap" }}
          >
            Redeem Gift Card
          </Typography>
        </Button>
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
          }}
          onClick={() => setCurrentType(ORDERTYPES.PROMOCODE)}
        >
          <Typography
            variant={matchesMD ? "h5" : "body1"}
            color="text.primary"
            sx={{ textWrap: "nowrap" }}
          >
            Redeem Promo Code
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

const WithdrawModal = ({
  setSelectedType,
  osrsValue,
  rs3Value,
  disabled,
  setCryptoType,
}: IExchangeOptions) => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));

  const handleCryptoUpdate = (type: string) => {
    setCryptoType(type);
    setSelectedType(exchangeData[5]);
  };
  return (
    <Stack
      gap={{ md: 1, xs: 0.5 }}
      direction="column"
      sx={{
        pointerEvents: disabled ? "none" : "auto",
        width: "100%",
      }}
    >
      <Stack
        direction={{ md: "row", sm: "column", xs: "column" }}
        flexWrap={{ md: "wrap", xs: "nowrap" }}
        gap={1}
      >
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
            opacity: disabled ? 0.5 : 1,
          }}
          onClick={() => setSelectedType(exchangeData[3])}
        >
          <Stack direction="row" gap={1}>
            <Typography
              variant={matchesMD ? "h5" : "body1"}
              color="text.primary"
              sx={{ textWrap: "nowrap" }}
            >
              OSRS GOLD
            </Typography>
            <img
              alt={"RuneScapeIcon"}
              src={RuneScapeIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            direction={{ md: "row", sm: "column", xs: "column" }}
          >
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              <TokenIcon />1 = {dollarsToGolds(osrsValue, true) || 0}m
            </Typography>
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              ({goldToDollars(osrsValue, true)}$/m)
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            cursor: "pointer",
            opacity: disabled ? 0.5 : 1,
          }}
          onClick={() => setSelectedType(exchangeData[4])}
        >
          <Stack direction="row" gap={1}>
            <Typography
              variant={matchesMD ? "h5" : "body1"}
              color="text.primary"
              sx={{ textWrap: "nowrap" }}
            >
              RS3 GOLD
            </Typography>
            <img
              alt={"RuneScapeIcon"}
              src={RuneScapeIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <Stack
            alignItems="center"
            direction={{ md: "row", sm: "column", xs: "column" }}
          >
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              <TokenIcon />1 = {dollarsToGolds(rs3Value, true) || 0}m
            </Typography>
            <Typography
              color="text.primary"
              sx={{ display: "flex", direction: "row", textTransform: "none" }}
            >
              ({goldToDollars(rs3Value, true)}$/m)
            </Typography>
          </Stack>
        </Button>
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: 2,
            px: 3,
            borderRadius: "8px",
            width: { md: "49%", sm: "100%" },
            height: { md: "auto", xs: "auto" },
            opacity: disabled ? 0.5 : 1,
            cursor: "pointer",
          }}
          disabled={disabled}
          onClick={() => handleCryptoUpdate(CryptoType.BTC)}
        >
          <Stack direction="row" gap={1}>
            <img
              alt={"BTC"}
              src={BTCIcon}
              style={{
                width: "2em",
              }}
            />
            <img
              alt={"LTC"}
              src={LTCIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
          <Typography
            variant={matchesMD ? "h5" : "body1"}
            color="text.primary"
            sx={{ textWrap: "nowrap", mx: 1 }}
          >
            Crypto
          </Typography>
          <Stack direction="row" gap={1}>
            <img
              alt={"ETH"}
              src={ETHIcon}
              style={{
                width: "2em",
              }}
            />
            <img
              alt={"SOL"}
              src={SOLIcon}
              style={{
                width: "2em",
              }}
            />
          </Stack>
        </Button>
      </Stack>

      {/* <Stack
        direction={{ xs: "row", sm: "row" }}
        flexWrap="wrap"
        justifyContent={{ xs: "space-around", md: "space-between" }}
        spacing={{ md: 0.1, sm: 0.3 }}
      >
        {CryptoOptions.map((option) => (
          <Stack
            key={option.type}
            sx={{
              width: { xs: "31%", md: "15%" },
              py: { xs: 0.5, md: 0 },
              boxSizing: "border-box",
            }}
          >
            <Button
              sx={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                justifyContent: "space-around",
                borderRadius: 2,
                width: "100%",
                cursor: "pointer",
                opacity: disabled ? 0.5 : 1,
              }}
              disabled={disabled}
              onClick={() => handleCryptoUpdate(option.type)}
            >
              <img
                alt={option.text}
                src={option.img}
                style={{
                  width: option.type === CryptoType.OTHERS ? "75%" : "60%",
                }}
              />
              <Typography
                color="text.primary"
                sx={{ display: "flex", direction: "row", mt: 1 }}
              >
                {option.text}
              </Typography>
            </Button>
          </Stack>
        ))}
      </Stack> */}
    </Stack>
  );
};

export const ExchangeOptions = ({
  setSelectedType,
  currentType,
  setCurrentType,
  disabled,
  setCryptoType,
  osrsValue,
  rs3Value,
}: IExchangeOptions) => {
  const changeModalType = (type: string) => {
    setCurrentType(type as ORDERTYPES);
  };

  const isBackButton =
    currentType === ORDERTYPES.PROMOCODE ||
    currentType === ORDERTYPES.REDEEMGIFT;

  const handleBack = () => {
    setCurrentType(ORDERTYPES.DEPOSIT);
  };
  return (
    <Stack gap={1}>
      <Stack
        direction={{ md: "row", sm: "column" }}
        alignItems={"center"}
        gap={{ md: 2, sm: 1, xs: 1 }}
      >
        <SelectionComponent
          changeModalType={changeModalType}
          buttonData={ExchangeSelection}
          modalType={
            currentType === ORDERTYPES.PROMOCODE ||
            currentType === ORDERTYPES.REDEEMGIFT
              ? ORDERTYPES.DEPOSIT
              : currentType
          }
          handleBack={handleBack}
          isBackButton={isBackButton}
        />
      </Stack>

      <Stack direction="row" gap={4}>
        {currentType === ORDERTYPES.DEPOSIT && (
          <DepositModal
            setSelectedType={setSelectedType}
            currentType={currentType}
            setCurrentType={setCurrentType}
            disabled={disabled}
            osrsValue={osrsValue}
            rs3Value={rs3Value}
            setCryptoType={setCryptoType}
          />
        )}

        {currentType === ORDERTYPES.WITHDRAWAL && (
          <WithdrawModal
            setSelectedType={setSelectedType}
            currentType={currentType}
            setCurrentType={setCurrentType}
            disabled={disabled}
            osrsValue={osrsValue}
            rs3Value={rs3Value}
            setCryptoType={setCryptoType}
          />
        )}
      </Stack>
    </Stack>
  );
};
