import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import Fairness from "../../services/fairness";
import Users from "../../services/user"; // Assuming this is correct
import {
  EXCHANGETYPE,
  IGameFeed,
  MODALTYPE,
  ORDERTYPES,
  TOKENTYPE,
} from "../../types/index.d";
import { RootState } from "../store";
import { checkCell, getMinesPayout } from "./mineSlice";
import { createOrder } from "./orderSlice";

export interface User {
  email: string;
  userName: string;
  referral?: string;
  balance: number;
  password?: string;
  isVerified?: boolean;
  clientSeed: string;
  gameNonce: number;
  totalWagered: number;
  userType: string;
  level: number;
  isBetConfirmation?: boolean;
  betConfirmationValue?: number;
  isAnonymity?: boolean;
  optInEmail?: boolean;
  rsn?: string;
  _id: string;
  // Staker
  isActive?: boolean;
  hitsRemaining?: number;
  healthy?: boolean;
  lastHeartBeat?: string;
  isMuted?: boolean;
  isBanned?: boolean;
  referred?: Referred[];

  referredBy?: string;
  totalReferralTokens?: number;
  availableReferralTokens?: number;
  referredLevel?: number;

  bonusLevel: number;
  bonuses: IBonus[];
  totalBonus: number;
  bonusOverride: boolean;
  discordUUID: string;
  verificationTokenTimeStamp?: Date;
  withdrawLock: number;
  freeCash: number;
  favouriteGames: string[];
}
export interface Referred {
  userName: string;
  profit: number;
  joinDate: Date;
}
export interface IBonus {
  type: string;
  available: number;
  total: number;
  lastClaimed: Date;
}
export interface UserInput {
  email: string;
  userName: string;
  password?: string;
  referral?: string;
  referredBy?: string;
  token: string;
}

export interface Constants {
  COIN_FLIP_TOGGLE: boolean;
  DICE_TOGGLE: boolean;
  HILO_TOGGLE: boolean;
  BLACKJACK_TOGGLE: boolean;
  DUEL_ARENA_TOGGLE: boolean;
  BGAMING_SLOTS_TOGGLE: boolean;
  CRYPTO_DEPOSITS_TOGGLE: boolean;
  KENO_TOGGLE: boolean;
  LIMBO_TOGGLE: boolean;
  MINES_TOGGLE: boolean;
  PLINKO_TOGGLE: boolean;
  SLIDE_TOGGLE: boolean;
  IS_MAINTENANCE: boolean;
  BUILD_VERSION: number;
}
export interface UserState {
  player: User;
  errorMessage: string;
  loading: boolean;
  constants: Constants;
  accessToken: string;
  isModalOpen: boolean;
  modalType: MODALTYPE | null;
  isChatDrawerOpen: boolean;
  isNavDrawerOpen: boolean;
  showLevelUpModal: boolean;
  tokensEarned: number;
  settingsLoading: boolean;
  claimLoading: boolean;
  bonusLoading: boolean;
  initialLoading: boolean;
  gameFeed: IGameFeed[] | null;
  fairnessLoading: boolean;
  volume: number;
  verificationModal: boolean;
  passwordResetMessage: string;
  passwordResetModal: boolean;
  isBlocked: boolean;
  isTyping: boolean;
  tokenSelection: TOKENTYPE;
  rs3: number;
  osrs: number;
  exchangeType: ORDERTYPES;
  isPlayingSlots: boolean;
  isFavouriteGamesLoading: boolean;
  currencies: string[];
}

const storedVolume = localStorage.getItem("volume");
const initialVolume = storedVolume ? parseInt(storedVolume, 10) : 100;

const storedTokenType = localStorage.getItem("tokenType");
const initialTokenType = storedTokenType
  ? (storedTokenType as TOKENTYPE)
  : TOKENTYPE.SWEEP;

const storedChatDrawer = localStorage.getItem("chatDrawer");
const initialStoredChat = storedChatDrawer
  ? storedChatDrawer === "true"
  : false;

const storedSideBarDrawer = localStorage.getItem("sideBar");
const initialStoredSideBar = storedSideBarDrawer
  ? storedSideBarDrawer === "true"
  : false;

const initialState: UserState = {
  player: {
    email: "",
    userName: "",
    referral: "",
    balance: 0,
    clientSeed: "",
    gameNonce: 0,
    isVerified: false,
    userType: "",
    level: 0,
    totalWagered: 0,
    _id: "",

    bonusLevel: 1,
    bonuses: [],
    totalBonus: 0,
    bonusOverride: false,
    discordUUID: "",
    withdrawLock: 0,
    freeCash: 0,
    favouriteGames: [],
  },
  constants: {
    COIN_FLIP_TOGGLE: true,
    DICE_TOGGLE: true,
    HILO_TOGGLE: true,
    BLACKJACK_TOGGLE: true,
    DUEL_ARENA_TOGGLE: true,
    BGAMING_SLOTS_TOGGLE: true,
    CRYPTO_DEPOSITS_TOGGLE: true,
    KENO_TOGGLE: true,
    LIMBO_TOGGLE: true,
    MINES_TOGGLE: true,
    PLINKO_TOGGLE: true,
    SLIDE_TOGGLE: true,
    IS_MAINTENANCE: false,
    BUILD_VERSION: 0,
  },
  accessToken: "",
  loading: false,
  errorMessage: "",
  isModalOpen: false,
  modalType: null,
  isChatDrawerOpen: initialStoredChat,
  isNavDrawerOpen: initialStoredSideBar,
  showLevelUpModal: false,
  claimLoading: false,
  tokensEarned: 0,
  settingsLoading: false,
  initialLoading: true,
  gameFeed: null,
  bonusLoading: false,
  fairnessLoading: false,
  volume: initialVolume,
  verificationModal: false,
  passwordResetMessage: "",
  passwordResetModal: false,
  isBlocked: false,
  isTyping: false,
  tokenSelection: initialTokenType,
  rs3: 0,
  osrs: 0,
  exchangeType: ORDERTYPES.DEPOSIT,
  isPlayingSlots: false,
  isFavouriteGamesLoading: false,
  currencies: [],
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (
    loginData: {
      userName: string;
      password: string;
      token: string;
      stayLoggedIn: boolean;
    },
    thunkAPI
  ) => {
    try {
      const user = await Users.login(
        loginData.userName,
        loginData.password,
        loginData.token,
        loginData.stayLoggedIn
      );
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (loginData: { email: string; token: string }, thunkAPI) => {
    try {
      const user = await Users.resetPassword(loginData.email, loginData.token);
      return user;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const claimBonus = createAsyncThunk(
  "user/claimBonus",
  async (data: { bonusType: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Users.claimBonus(accessToken, data.bonusType);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const calimReferralReward = createAsyncThunk(
  "user/calimReferralReward",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Users.calimReferralReward(accessToken);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const updateSeed = createAsyncThunk(
  "user/updateSeed",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Fairness.put(accessToken);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const updateSettings = createAsyncThunk(
  "user/updateSettings",
  async (userData: Partial<User>, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Users.put(
        userData?.isBetConfirmation,
        userData?.optInEmail,
        userData?.isAnonymity,
        userData?.betConfirmationValue,
        accessToken
      );

      return user; // Ensure this matches the User interface
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const addFavourite = createAsyncThunk(
  "user/addFavourite",
  async (userData: { game: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Users.addFavourite(userData?.game, accessToken);

      return user; // Ensure this matches the User interface
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (userData: UserInput, thunkAPI) => {
    try {
      const user = await Users.post(
        userData.email,
        userData.password as string,
        userData.userName,
        userData.token,
        userData.referral,
        userData.referredBy
      );

      return user; // Ensure this matches the User interface
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const verifyUser = createAsyncThunk(
  "user/verify",
  async (_, thunkAPI) => {
    try {
      const response = await Users.verifyUser();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const getUser = createAsyncThunk("user/get", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    const response = await Users.getUser(accessToken);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error instanceof Error ? error.message : "An unknown error occurred"
    );
  }
});

export const getCurrencies = createAsyncThunk(
  "user/getCurrencies",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const response = await Users.getCurrencies(accessToken);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserState: (state) => initialState,
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    updateModalType: (state, action: PayloadAction<MODALTYPE>) => {
      state.modalType = action.payload;
    },
    updateVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
      localStorage.setItem("volume", action.payload.toString());
    },
    openModal: (state, action: PayloadAction<MODALTYPE>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
    updateVerificationModal: (state, action: PayloadAction<boolean>) => {
      state.verificationModal = action.payload;
    },
    updatePasswordResetModal: (state, action: PayloadAction<boolean>) => {
      state.passwordResetModal = action.payload;
    },
    updateChatDrawer: (state, action: PayloadAction<boolean>) => {
      state.isChatDrawerOpen = action.payload;
      localStorage.setItem("chatDrawer", action.payload.toString());
    },
    updateNavDrawer: (state, action: PayloadAction<boolean>) => {
      state.isNavDrawerOpen = action.payload;
      localStorage.setItem("sideBar", action.payload.toString());
    },
    updateBalance: (state, action: PayloadAction<number>) => {
      state.player.balance = action.payload;
    },
    updateFreeCash: (state, action: PayloadAction<number>) => {
      state.player.freeCash = action.payload;
    },
    addBalance: (state, action: PayloadAction<number>) => {
      state.player.balance = state.player.balance + action.payload;
    },
    addFreeCash: (state, action: PayloadAction<number>) => {
      state.player.freeCash = state.player.freeCash + action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if (action.payload.isBanned) {
        state.player = initialState.player;
        state.accessToken = "";
      } else {
        state.player = action.payload;
      }
    },
    logoutUser: (state) => {
      state.player = initialState.player;
      state.accessToken = "";
    },
    updateLevelUpModal: (state, action: PayloadAction<boolean>) => {
      state.showLevelUpModal = action.payload;
    },
    updateExchangeType: (state, action: PayloadAction<ORDERTYPES>) => {
      state.exchangeType = action.payload;
    },
    updatetokensEarned: (state, action: PayloadAction<number>) => {
      state.tokensEarned = action.payload;
    },
    updateConstants: (state, action: PayloadAction<Constants>) => {
      state.constants = action.payload;
    },
    updateInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.initialLoading = action.payload;
    },
    updateIsTyping: (state, action: PayloadAction<boolean>) => {
      state.isTyping = action.payload;
    },
    updateTokenSelection: (state, action: PayloadAction<TOKENTYPE>) => {
      state.tokenSelection = action.payload;
      localStorage.setItem("tokenType", action.payload.toString());
    },

    updateIsPlayingSlots: (state, action: PayloadAction<boolean>) => {
      state.isPlayingSlots = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state, action) => {
        state.player = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.errorMessage = "";
        state.isModalOpen = false;
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        const {
          isBetConfirmation,
          optInEmail,
          isAnonymity,
          betConfirmationValue,
        } = action.payload;
        if (isBetConfirmation !== null) {
          state.player.isBetConfirmation = isBetConfirmation;
        }
        if (optInEmail !== null) {
          state.player.optInEmail = optInEmail;
        }
        if (isAnonymity !== null) {
          state.player.isAnonymity = isAnonymity;
        }
        if (betConfirmationValue !== null) {
          state.player.betConfirmationValue = betConfirmationValue;
        }
        state.settingsLoading = false;
      })
      .addCase(updateSettings.rejected, (state, action) => {
        state.settingsLoading = false;
      })
      .addCase(updateSettings.pending, (state) => {
        state.settingsLoading = true;
      })
      .addCase(addFavourite.fulfilled, (state, action) => {
        const { favouriteGames } = action.payload;

        if (favouriteGames) {
          state.player.favouriteGames = [...favouriteGames];
        }
        state.isFavouriteGamesLoading = false;
      })
      .addCase(addFavourite.rejected, (state, action) => {
        state.isFavouriteGamesLoading = false;
      })
      .addCase(addFavourite.pending, (state) => {
        state.isFavouriteGamesLoading = true;
      })
      .addCase(claimBonus.fulfilled, (state, action) => {
        if (action.payload) {
          state.player = action.payload.user;
        }
        state.bonusLoading = false;
      })
      .addCase(claimBonus.rejected, (state, action) => {
        state.bonusLoading = false;
      })
      .addCase(claimBonus.pending, (state) => {
        state.bonusLoading = true;
      })
      .addCase(calimReferralReward.fulfilled, (state, action) => {
        if (action.payload) {
          state.player = action.payload.user;
        }
        state.claimLoading = false;
      })
      .addCase(calimReferralReward.rejected, (state, action) => {
        state.claimLoading = false;
      })
      .addCase(calimReferralReward.pending, (state) => {
        state.claimLoading = true;
      })

      .addCase(updateSeed.fulfilled, (state, action) => {
        if (action.payload) {
          state.player = action.payload.user;
        }
        state.fairnessLoading = false;
      })
      .addCase(updateSeed.rejected, (state, action) => {
        state.fairnessLoading = false;
      })
      .addCase(updateSeed.pending, (state) => {
        state.fairnessLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        if (action.payload.isBlocked) {
          state.isBlocked = action.payload.isBlocked;
          state.errorMessage = "";
          state.initialLoading = false;
        } else {
          state.player = action.payload.user;
          state.accessToken = action.payload.accessToken;
          state.errorMessage = "";
          state.initialLoading = false;
        }
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.initialLoading = false;
      })
      .addCase(verifyUser.pending, (state) => {
        state.initialLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload.isBlocked) {
          state.isBlocked = action.payload.isBlocked;
        } else {
          state.player = action.payload.user;
          state.osrs = action.payload.osrs;
          state.rs3 = action.payload.rs3;
        }
        state.errorMessage = "";
        state.initialLoading = false;
      })

      .addCase(getCurrencies.fulfilled, (state, action) => {
        if (action.payload) {
          state.currencies = action.payload?.currencies;
        }
      })
      .addCase(getUser.rejected, (state, action) => {
        state.initialLoading = false;
      })
      .addCase(getUser.pending, (state) => {
        state.initialLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.player = action.payload.user;
        state.accessToken = action.payload.accessToken;
        state.isModalOpen = false;
        state.errorMessage = "";
        state.loading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action: any) => {
        state.passwordResetMessage = action.payload.messsage as string;
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkCell.fulfilled, (state, action) => {
        if (action.payload?.isWin) {
          state.player.balance = action.payload.balance;
        }
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        if (
          action.payload.order &&
          action.payload.order.type === EXCHANGETYPE.DEPOSITCRYPTO
        ) {
          state.modalType = MODALTYPE.CRYPTODEPOSIT;
        } else {
          state.isModalOpen = false;
        }
        if (action.payload && !state.isChatDrawerOpen) {
          state.isChatDrawerOpen = true;
        }
        if (action?.payload?.balance !== undefined) {
          state.player.balance = action.payload.balance;
        }
      })
      .addCase(getMinesPayout.fulfilled, (state, action) => {
        if (action?.payload?.balance) {
          state.player.balance = action.payload.balance;
        }
      });
  },
});
export const selectPlayer = (state: RootState) => state.user;
export const selectCurrentUser = (state: RootState) => state.user.player;
export const selectToken = (state: RootState) => state.user.accessToken;
export const selectSettingsLoading = (state: RootState) =>
  state.user.settingsLoading;

export const selectClaimLoading = (state: RootState) => state.user.claimLoading;
export const selectInitialLoading = (state: RootState) =>
  state.user.initialLoading;
export const selectChatDrawer = (state: RootState) =>
  state.user.isChatDrawerOpen;
export const selectNavDrawer = (state: RootState) => state.user.isNavDrawerOpen;
export const selectLevelUpModal = (state: RootState) =>
  state.user.showLevelUpModal;

export const selectIsModalOpen = (state: RootState) => state.user.isModalOpen;
export const selectModalType = (state: RootState) => state.user.modalType;
export const selectTokensEarned = (state: RootState) => state.user.tokensEarned;
// User data
export const selectUserType = (state: RootState) => state.user.player.userType;
export const selectUserName = (state: RootState) => state.user.player.userName;
export const selectId = (state: RootState) => state.user.player._id;
export const selectBalance = (state: RootState) => state.user.player.balance;
export const selectFreeCash = (state: RootState) =>
  state.user.player.freeCash || 0;
export const selectTokenSelection = (state: RootState) =>
  state.user.tokenSelection;
export const selectWithdrawLock = (state: RootState) =>
  state.user.player.withdrawLock;
export const selectPlayerLevel = (state: RootState) => state.user.player.level;
export const selectPlayerDiscord = (state: RootState) =>
  state.user.player.discordUUID;

//Toggles
export const selectMinesToggle = (state: RootState) =>
  state.user.constants.MINES_TOGGLE;
export const selectCoinFlipToggle = (state: RootState) =>
  state.user.constants.COIN_FLIP_TOGGLE;
export const selectDuelArenaToggle = (state: RootState) =>
  state.user.constants.DUEL_ARENA_TOGGLE;

export const selectBGamingToggle = (state: RootState) =>
  state.user.constants.BGAMING_SLOTS_TOGGLE;

export const selectCryptoToggle = (state: RootState) =>
  state.user.constants.CRYPTO_DEPOSITS_TOGGLE;
export const selectKenoToggle = (state: RootState) =>
  state.user.constants.KENO_TOGGLE;
export const selectLimboToggle = (state: RootState) =>
  state.user.constants.LIMBO_TOGGLE;
export const selectPlinkoToggle = (state: RootState) =>
  state.user.constants.PLINKO_TOGGLE;
export const selectSlideToggle = (state: RootState) =>
  state.user.constants.SLIDE_TOGGLE;
export const selectDiceToggle = (state: RootState) =>
  state.user.constants.DICE_TOGGLE;
export const selectBlackJackToggle = (state: RootState) =>
  state.user.constants.BLACKJACK_TOGGLE;

export const selectHiloToggle = (state: RootState) =>
  state.user.constants.HILO_TOGGLE;

export const selectMaintenance = (state: RootState) =>
  state.user.constants.IS_MAINTENANCE;

export const selectBuildVersion = (state: RootState) =>
  state.user.constants.BUILD_VERSION;

export const selectFairnessLoading = (state: RootState) =>
  state.user.fairnessLoading;

export const selectClientSeed = (state: RootState) =>
  state.user.player.clientSeed;

export const selectVolume = (state: RootState) => state.user.volume;
export const selectVerificationModal = (state: RootState) =>
  state.user.verificationModal;
export const selectPasswordResetModal = (state: RootState) =>
  state.user.passwordResetModal;
export const selectIsBlocked = (state: RootState) => state.user.isBlocked;
export const selectIsTyping = (state: RootState) => state.user.isTyping;
export const selectOSRSRate = (state: RootState) => state.user.osrs;
export const selectRS3Rate = (state: RootState) => state.user.rs3;
export const selectExchangeType = (state: RootState) => state.user.exchangeType;
export const selectIsPlayingSlots = (state: RootState) =>
  state.user.isPlayingSlots;

export const selectFavouriteGames = (state: RootState) =>
  state.user.player.favouriteGames || [];

export const selectIsFavouriteLoading = (state: RootState) =>
  state.user.isFavouriteGamesLoading;

export const selectCurrencies = (state: RootState) => state.user.currencies;

export const {
  setErrorMessage,
  resetUserState,
  updateVolume,
  updateModalType,
  openModal,
  closeModal,
  updateChatDrawer,
  updateBalance,
  updateUser,
  updateLevelUpModal,
  updatetokensEarned,
  addBalance,
  updateConstants,
  updateVerificationModal,
  updatePasswordResetModal,
  logoutUser,
  updateInitialLoading,
  updateNavDrawer,
  updateIsTyping,
  updateTokenSelection,
  updateFreeCash,
  updateExchangeType,
  updateIsPlayingSlots,
  addFreeCash,
} = userReducer.actions;

export default userReducer.reducer;
// const tokenSelection = useAppSelector(selectTokenSelection)

// const setTokenSelection = (value: boolean) => {
//   dispatch(updateTokenSelection(value));
// };
