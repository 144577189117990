import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Chip, Typography, Stack } from "@mui/material";
import Card from "./card";
import * as BlackJackUtilities from "./constants";
import styles from "./index.module.scss";
import { motion } from "framer-motion";
import useSound from "use-sound";
import { theme } from "../theme";
import { selectVolume } from "../slices/userSlice";
import BetSound from "../../assets/audio/blackjack/deal.wav";
import { useAppSelector } from "../store/hooks";

interface PlayAreaProps {
  dealersHand: BlackJackUtilities.Card[] | undefined;
  playersHands: any[][] | undefined;
  isTurnEnd: boolean | undefined;
  currentPlayerHandIndex: number | undefined;
  deckRef: any;
  isGameEnd: boolean | undefined;
}
const AnimatedGridItem = motion(Grid);
const getBlackJackColor = (text: string) => {
  switch (text) {
    case "WIN":
      return "#32c932 !important";
    case "BLACK JACK":
      return "#32c932 !important";
    case "LOSE":
      return "#D92324 !important";
    case "PUSH":
      return "#ffa000 !important";
    default:
      return "white !important";
  }
};

const PlayArea: React.FC<PlayAreaProps> = (props) => {
  const playerCardsRef = useRef<any>();
  const volume = useAppSelector(selectVolume);
  const [animatedIndex, setAnimatedIndex] = useState(-1);
  const [animatedIndexSplit, setAnimatedIndexSplit] = useState(-1);
  const [animatedIndexDealer, setAnimatedIndexDealer] = useState(-1);
  const [isAnimatingDealer, setIsAnimatingDealer] = useState(false);
  const [playDealSound] = useSound(BetSound, { volume: volume / 100 });

  const {
    dealersHand: dealersHandOriginal,
    playersHands,
    isTurnEnd,
    currentPlayerHandIndex,
    deckRef,
    isGameEnd,
  } = props;
  const [deckX, setDeckX] = useState("100%");
  const [deckY, setDeckY] = useState("-100%");
  const [dealersHand, setDealerHand] = useState<
    BlackJackUtilities.Card[] | undefined
  >();

  const isFinishDealing = dealersHandOriginal?.length === dealersHand?.length;

  const updateDealerHand = () => {
    if (dealersHandOriginal && dealersHand) {
      const isSecondCardOri = dealersHandOriginal[1].rank === "U";
      const isSecondCard = dealersHand[1].rank === "U";

      if (isSecondCardOri !== isSecondCard) {
        setIsAnimatingDealer(true);
        setDealerHand([dealersHandOriginal[0], dealersHandOriginal[1]]);
        return;
      }
    }
    if (
      dealersHandOriginal &&
      animatedIndexDealer < dealersHandOriginal.length - 1
    ) {
      const newEntry: any = dealersHandOriginal[animatedIndexDealer + 1];
      const isPresent = dealersHand?.find(
        (hand: any) => hand._id === newEntry._id
      );
      if (!isPresent) {
        setIsAnimatingDealer(true);
        setDealerHand((prevHand) => [
          ...(prevHand || []),
          dealersHandOriginal[animatedIndexDealer + 1],
        ]);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(dealersHandOriginal) === JSON.stringify(dealersHand)) {
      return;
    } else {
      updateDealerHand();
    }
    if (dealersHandOriginal === undefined || dealersHand === undefined) {
      setDealerHand(dealersHandOriginal);
    }
  }, [dealersHandOriginal, animatedIndexDealer, isTurnEnd, isAnimatingDealer]);

  useEffect(() => {
    if (deckRef.current && playerCardsRef.current) {
      const rect1 = deckRef.current.getBoundingClientRect();
      const rect2 = playerCardsRef.current.getBoundingClientRect();

      const diffX = rect2.left - rect1.left;
      const diffY = rect2.top - rect1.top;

      setDeckX(`${diffX * -1 - 100}px`);
      setDeckY(`${diffY * -1}px`);
    }
  }, [deckRef.current]);

  return (
    <>
      <Box>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" sx={{ pb: 1 }}>
              {dealersHand && (
                <Stack
                  sx={{
                    bgcolor: "#213240",
                    borderRadius: "5px",
                    minWidth: "2.5em",
                    p: 0.5,
                  }}
                >
                  <Typography
                    color="text.primary"
                    textAlign="center"
                    fontWeight={600}
                  >
                    {dealersHand &&
                      dealersHand?.length !== 0 &&
                      (isTurnEnd
                        ? BlackJackUtilities.getScoreForDisplayDealer(
                            dealersHand
                          )
                        : BlackJackUtilities.getScoreForDisplayDealerBeforeEnd(
                            dealersHand
                          ))}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Grid container direction="row" flexWrap="nowrap">
              {dealersHand &&
                dealersHand.map((card, index) => {
                  const marginLeft = index === 0 ? "0px" : "-50px";
                  const marginTop = index === 0 ? "0px" : `${index * 0}px`;
                  const hide = index === 1 && !props.isTurnEnd;
                  return (
                    <AnimatedGridItem
                      item
                      key={index}
                      initial={{
                        opacity: 1,
                        x: deckX,
                        y: deckY,
                      }}
                      onAnimationStart={() => {
                        playDealSound();
                        setIsAnimatingDealer(true);
                      }}
                      animate={{ opacity: 1, x: 0, y: 0, position: "relative" }}
                      transition={{
                        delay: dealersHand.length === 1 ? index * 0.2 : 0,
                        duration: 0.5,
                      }}
                      style={{
                        marginLeft: marginLeft,
                        marginTop: marginTop,
                        zIndex: index + 1,
                        overflow: "visible",
                      }}
                      onAnimationComplete={() => {
                        setIsAnimatingDealer(false);
                        setAnimatedIndexDealer(index);
                      }}
                    >
                      <Card
                        card={card}
                        hide={animatedIndexDealer >= index ? false : hide}
                      />
                    </AnimatedGridItem>
                  );
                })}
            </Grid>
          </Grid>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ p: 1, mt: 2 }}
          >
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              BLACKJACK PAYS 3 TO 2
            </Typography>
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              INSURANCE PAYS 2 TO 1
            </Typography>
          </Stack>

          <Grid item sx={{ pt: 0 }}>
            <Stack direction="row" gap={3} ref={playerCardsRef}>
              {playersHands &&
                playersHands.map(
                  (
                    playersHand: BlackJackUtilities.Card[],
                    handIndex: number
                  ) => {
                    let bgColor = "#213240";
                    let color = theme.palette.text.primary;
                    if (isGameEnd && isFinishDealing && dealersHand) {
                      bgColor = getBlackJackColor(
                        BlackJackUtilities.judge(dealersHand, playersHand)
                      );
                      color = "";
                    }
                    return (
                      <Stack direction="column" justifyContent="center">
                        <Stack alignItems="center" sx={{ pb: 1 }}>
                          <Stack
                            sx={{
                              bgcolor: bgColor,
                              borderRadius: "5px",
                              minWidth: "2.5em",
                              p: 0.5,
                            }}
                          >
                            <Typography
                              sx={{ color: color }}
                              textAlign="center"
                              fontWeight={600}
                            >
                              {playersHand.length !== 0 &&
                                BlackJackUtilities.getScoreForDisplay(
                                  playersHand
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Grid
                          container
                          direction="row"
                          flexWrap="nowrap"
                          sx={{
                            opacity:
                              handIndex === currentPlayerHandIndex ? 1 : 0.5,
                          }}
                        >
                          {dealersHand &&
                            playersHand.map(
                              (
                                card: BlackJackUtilities.Card,
                                index: number
                              ) => {
                                const marginLeft =
                                  index === 0 ? "0px" : "-50px";
                                const marginTop =
                                  index === 0 ? "0px" : `${index * 0}px`;
                                const border = `3.5px solid ${getBlackJackColor(
                                  BlackJackUtilities.judge(
                                    dealersHand,
                                    playersHand
                                  )
                                )?.replace("!important", "")}`;

                                let isHide =
                                  animatedIndex >= index ? false : true;
                                if (handIndex === 1) {
                                  isHide =
                                    animatedIndexSplit >= index ? false : true;
                                }
                                return (
                                  <AnimatedGridItem
                                    item
                                    key={index}
                                    initial={{
                                      x: deckX,
                                      y: deckY,
                                      marginLeft: marginLeft,
                                      position: "fixed",
                                    }}
                                    onAnimationStart={() => {
                                      playDealSound();
                                    }}
                                    animate={{
                                      x: 0,
                                      y: 0,

                                      marginLeft: marginLeft,
                                      transform: "",
                                      position: "relative",
                                    }}
                                    transition={{
                                      delay:
                                        playersHand.length === 2
                                          ? index * 0.2
                                          : 0,
                                      duration: 0.5,
                                    }}
                                    style={{
                                      marginLeft: marginLeft,
                                      marginTop: marginTop,
                                      zIndex: index + 1,
                                    }}
                                    onAnimationComplete={() => {
                                      if (handIndex === 1) {
                                        setAnimatedIndexSplit(index);
                                      } else {
                                        setAnimatedIndex(index);
                                      }
                                    }}
                                  >
                                    <Card
                                      card={card}
                                      hide={isHide}
                                      border={
                                        isGameEnd && isFinishDealing
                                          ? border
                                          : ""
                                      }
                                    />
                                  </AnimatedGridItem>
                                );
                              }
                            )}
                        </Grid>
                      </Stack>
                    );
                  }
                )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PlayArea;
